import { Tooltip } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, take } from 'rxjs'
import { UploadApi } from 'src/api'
import { Button, Editor, ErrorMessage, GoogleLocationSearch, Input, LocationSearch, TourPopper, useAnalytic } from 'src/components'
import { EEnv } from 'src/constants'
import { ELocationType, ETrackingEvent } from 'src/enums'
import { ErrorBag, useBehaviorMapper } from 'src/hooks'
import { IconInfo } from 'src/icons'
import { ICampaignDetail } from 'src/interfaces'
import { useUploadRecord } from 'src/modules/campaign/hooks/useUploadRecord'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, ERecordFor, ProfileService } from 'src/services'
import { CreateJobTourService, TARGET_DESCRIPTION, TARGET_ENTER_JOB_DETAIL } from 'src/services/tour/create-job-tour.service'
import { getApiErrorMessage, getVideoSource, isEmptyBriefDescription } from 'src/utils'
import { AttachedFile } from '../attached-file'
import { IntroduceYourselfVideo } from '../introduce-yoursefl-video'
import { WorkplaceType } from '../workplace-type'
import { Salary } from './salary'
import Style from './style.module.scss'

const inputSx = { marginBottom: 0 }

export const CampaignDetail: FC<{
  errors?: ErrorBag<ICampaignDetail>
  otherErrors?: {
    briefDescription?: boolean
    uploadVideo?: boolean
  }
  focusedErr?: string
}> = ({ errors: externalErrors, otherErrors, focusedErr }) => {
  const router = useHistory()
  const { eventHandler } = useAnalytic()
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)

  const [fileName, setFileName] = useState<string>()

  const data = useBehaviorMapper(CampaignMutationService.data$)
  useUploadRecord()

  const handleOnChangeUploadVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    CampaignMutationService.patchData({ uploadVideoFile: file })

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_HM_VIDEO_UPLOAD,
      contextData: { file }
    })()
  }, [eventHandler])

  const handleJdFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    CampaignMutationService.patchData({ jdFile: file, _ignoreDraft: true })
    const { jdFileUrl } = await CampaignMutationService.uploadFilesAndSaveDraft({ jdFile: file })
    CampaignMutationService.patchData({ jdFileUrl, _ignoreDraft: true })

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_INPUT_JOB_ATTACH_FILE,
      contextData: { jdFile: file }
    })()

    if (/\.pdf$/i.test(file.name)) {
      return
    }

    setErrors((prev) => prev.clearError('briefDescription'))

    from(UploadApi.fetchContent(file))
      .pipe(
        take(1),
        catchError((error: AxiosError) => {
          setErrors((prev) => prev.setError('briefDescription', getApiErrorMessage(error)))
          return EMPTY
        }),
        finalize(() => {})
      )
      .subscribe(({ data }) => {
        if (data.content) {
          const content = data.content
            .replace(/<h1/g, '<strong').replace(/<\/h1>/g, '</strong>')
            .replace(/<h2/g, '<strong').replace(/<\/h2>/g, '</strong>')
            .replace(/<h3/g, '<strong').replace(/<\/h3>/g, '</strong>')
            .replace(/<h4/g, '<strong').replace(/<\/h4>/g, '</strong>')
            .replace(/<h5/g, '<strong').replace(/<\/h5>/g, '</strong>')
            .replace(/<h6/g, '<strong').replace(/<\/h6>/g, '</strong>')

          const briefDescription = isEmptyBriefDescription(content) ? '' : content
          return CampaignMutationService.patchData({ briefDescription })
        }

        setErrors((prev) => prev.setError('briefDescription', 'Your attachment has no content.'))
      })
  }, [eventHandler])

  const handleRecordVideo = useCallback(() => {
    router.push(generate(ERoutes.RECORDING, { type: ERecordFor.CAMPAIGN }))

    eventHandler(ETrackingEvent.CAMPAIGN_BTN_HM_VIDEO_CREATE)()
  }, [eventHandler, router])

  const handleUsePfv = useCallback(() => {
    const vvc = ProfileService.settingProfile$.getValue()?.video
    CampaignMutationService.patchData({
      uploadVideo: vvc,
      uploadVideoUrl: getVideoSource(vvc),
      _ignoreDraft: true
    })

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_HM_VIDEO_PROFILE,
      contextData: {
        uploadVideoUrl: getVideoSource(vvc)
      }
    })()
  }, [eventHandler])

  const {
    debounceSendAnalyticJobTitle,
    debounceSendAnalyticJobLocation,
    debounceSetJobDescriptionAnalytic
  } = useMemo(() => ({
    debounceSeAnalyticJobName: debounce((jobName: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_NAME,
        contextData: { jobName }
      })()
    }, 500),
    debounceSendAnalyticJobTitle: debounce((jobTitle: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_TITLE,
        contextData: { jobTitle }
      })()
    }, 500),
    debounceSendAnalyticJobLocation: debounce((location: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_LOCATION,
        contextData: { location }
      })()
    }, 500),
    debounceSetJobDescriptionAnalytic: debounce((briefDescription: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_DESCRIPTION,
        contextData: { briefDescription }
      })()
    }, 500),
    debounceSendJobSummaryAnalytic: debounce((jobSummary: string) => {
      eventHandler({
        key: ETrackingEvent.CAMPAIGN_INPUT_JOB_SUMMARY,
        contextData: { jobSummary }
      })()
    }, 500)
  }), [eventHandler])

  const handleOnChangeJobTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const jobTitle = e.target.value
    const toPatchData: Partial<ICampaignDetail> = { jobTitle }

    CampaignMutationService.patchData(toPatchData)
    debounceSendAnalyticJobTitle(e.target.value)
  }, [debounceSendAnalyticJobTitle])

  const handleOnChangeJobLocation = useCallback((location: string) => {
    CampaignMutationService.patchData({ location })
    debounceSendAnalyticJobLocation(location)
  }, [debounceSendAnalyticJobLocation])

  const handleOnChangeLocationType = useCallback((value: ELocationType) => {
    const patchedData: Partial<ICampaignDetail> = { locationType: value }
    if (value === ELocationType.REMOTE) {
      patchedData.location = ''
    }

    CampaignMutationService.patchData(patchedData)

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_CHECKBOX_JOB_REMOTE,
      contextData: { locationType: value }
    })()
  }, [eventHandler])

  const handleOnChangeBriefDescription = useCallback((description: string, _: any, source: string) => {
    if (source !== 'user') {
      return
    }

    const briefDescription = isEmptyBriefDescription(description) ? '' : description

    CampaignMutationService.patchData({ briefDescription })
    debounceSetJobDescriptionAnalytic(description)
  }, [debounceSetJobDescriptionAnalytic])

  const [errors, setErrors] = useState(new ErrorBag<ICampaignDetail>())

  useEffect(() => {
    if (data.uploadedJdFile) {
      return setFileName(data.uploadedJdFile.filename)
    }

    if (data.jdFile) {
      return setFileName(data.jdFile.name)
    }

    if (data.jdFileUrl) {
      return setFileName(data.jdFileUrl.split('/').pop())
    }

    setFileName(undefined)
  }, [data])

  return (
    <div className={Style.container}>
      <span className={Style.header}>
        Job Detail
      </span>
      <div className={Style.content}>
        <div className={Style.campaignWrapper}>
          <div id="uploadVideo">
            <IntroduceYourselfVideo
              uploadVideoFile={data.uploadVideoFile}
              uploadVideoUrl={data.uploadVideoUrl}
              onRecordVideo={handleRecordVideo}
              onChangeUploadVideo={handleOnChangeUploadVideo}
              onUsePfv={handleUsePfv}
              videoBackground={data.uploadAudioBackground?.file || data.uploadAudioBackground?.src || data.uploadVideo?.urlVideoImageThumbnail}
            />
            {
              otherErrors?.uploadVideo && (
                <ErrorMessage className="mt-2">
                  This field is required
                </ErrorMessage>
              )
            }
          </div>
          <div className="fx-1 gap-6 fx flex-column relative">
            <div className={Style.jobDetailTarget} id={TARGET_ENTER_JOB_DETAIL.getValue()}/>
            {tourEnabled && currentTourStep === 2 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

            <div className={Style.campaignTitle} id="jobTitle">
              <span className={clsx(Style.title, Style.title_required)}>Job Title</span>
              <Input
                sx={inputSx}
                id="jobTitleInput"
                placeholder="Job Title"
                error={externalErrors?.getError('jobTitle')}
                value={data.jobTitle || ''}
                onChange={handleOnChangeJobTitle}
              />
            </div>

            <div className={Style.salary} id="salaryValue">
              <span className={clsx(Style.title, Style.title_required)}>Salary</span>
              <div className="fx-column gap-2">
                <Salary
                  id="salaryValueInput"
                  rate={data.salaryRate}
                  range={data.salaryRange}
                  value={data.salaryValue}
                  onChange={(salary) => CampaignMutationService.patchData({
                    salaryValue: salary.value,
                    salaryRange: salary.range,
                    salaryRate: salary.rate
                  })}
                  error={externalErrors?.getError('salaryValue') || externalErrors?.getError('salaryValue.min') || externalErrors?.getError('salaryValue.max')}
                />
              </div>
            </div>

            <div className="fx flex-row fx-ai-flex-start gap-4" id="location">
              <WorkplaceType
                value={data.locationType}
                onChange={handleOnChangeLocationType}
              />
              {data.locationType !== ELocationType.REMOTE && (
                <div className={Style.location}>
                  <div className="fx flex-row fx-ai-center gap-2">
                    <span className={clsx(Style.title, Style.title_required)}>Location</span>
                    <Tooltip
                      title="Picking a specific city or metro area can help make your job more discoverable by job seekers in those areas, while still remaining visible to everyone in your country or region."
                      classes={{ popper: Style.popper, tooltip: clsx('meta-medium', Style.tooltip), arrow: Style.tooltipArrow }}
                      placement="top"
                      arrow
                    >
                      <IconInfo
                        className={Style.iconInfo}
                      />
                    </Tooltip>
                  </div>

                  {EEnv.REACT_APP_LOCATION_PROVIDER === 'google'
                    ? (
                      <GoogleLocationSearch
                        id="locationInput"
                        location={data.location}
                        onChange={handleOnChangeJobLocation}
                        error={externalErrors?.getError('location')}
                      />
                    )
                    : (
                      <LocationSearch
                        id="locationInput"
                        location={data.location}
                        onChange={handleOnChangeJobLocation}
                        error={externalErrors?.getError('location')}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className={Style.question}>
          <div className={Style.question_header}>
            <div className="fx flex-row gap-2 fx-ai-center">
              <span className={clsx(Style.title_bold, Style.title_required)}>Choose One Question for Applicants to Answer</span>
              <Tooltip
                title="Once a candidate applies you can ask additional questions later."
                classes={{ popper: Style.popper, tooltip: clsx('meta-medium', Style.tooltip), arrow: Style.tooltipArrow }}
                arrow
                placement="right"
              >
                <IconInfo
                  className={Style.iconInfo}
                />
              </Tooltip>
            </div>

          </div>
          <RadioGroup
            value={data.questionIds?.at(0) || 0}
            name="questionIds"
            className={Style.question_content}
            onChange={handleOnSelectJobQuestion}
          >
            {questions.map((question) => (
              <Radio key={question.id} label={question.text} value={question.id}/>
            ))}
          </RadioGroup>

          <div className={Style.questionTarget} id={TARGET_POTENTIAL_CANDIDATES.getValue()}/>
          {tourEnabled && currentTourStep === 3 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}
        </div> */}

        <div className={clsx(Style.jd, 'fx fx-column gap-6 pt-4 relative')}>
          <div className={Style.jobDescriptionTarget} id={TARGET_DESCRIPTION.getValue()}/>
          {tourEnabled && currentTourStep === 4 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

          <div className="fx fx-column" id="briefDescription">
            <div className="fx fx-column gap-1 px-4 mb-4">
              <span className={clsx(Style.title_bold, Style.title_required)}>Job Description</span>
            </div>

            <Editor
              focused={focusedErr === 'briefDescriptionInput'}
              value={data.briefDescription}
              onChange={handleOnChangeBriefDescription}
              className={clsx(Style.jdEditor, fileName !== undefined && Style.jdEditorWithAttachment)}
            />

            {/* <EditorPlaceholder/> */}

            <div className={clsx(Style.jdAttachmentWrapper, fileName !== undefined && Style.jdWithAttachment)}>
              {fileName !== undefined
                ? (
                  <AttachedFile
                    fileName={fileName}
                    url={data.jdFileUrl}
                    onRemove={() => CampaignMutationService.patchData({
                      jdFile: undefined,
                      jdFileUrl: null,
                      uploadedJdFile: undefined
                    })}
                  />
                )
                : (
                  <Button
                    field="input"
                    accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    order="tertiary"
                    className={Style.jd_attach_button}
                    handleChange={handleJdFileChange}
                  >
                    <span className="text-decorator-underline">Attach file</span>
                  </Button>
                )}
            </div>

            {(errors.hasError('briefDescription') || otherErrors?.briefDescription) && (
              <ErrorMessage className="px-4 my-2">
                {errors.getError('briefDescription') || 'This field is required'}
              </ErrorMessage>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
